import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Layout from "../components/layout"
import SEO from "../components/seo"

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    margin: theme.spacing(4, 0),
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} target="_blank" />;
}

const AboutPage = () => {
  const classes = useStyles();

  return (<Layout>
    <SEO title="About" />
    <h1>ABOUT</h1>
    <List
      component="nav"
      className={classes.root}
    >
      <ListItemLink href="https://old.fancyoung.com">
        <ListItemText primary="老版博客(Octopress)" />
      </ListItemLink>
    </List>
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          技术栈
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItemLink href="https://www.gatsbyjs.com/">
        <ListItemText primary="Gatsby" />
      </ListItemLink>
      <ListItemLink href="https://material-ui.com/">
        <ListItemText primary="Meterial-UI" />
      </ListItemLink>
      <ListItemLink href="https://mdxjs.com/">
        <ListItemText primary="MDX (Markdown)" />
      </ListItemLink>
      <ListItemLink href="https://hackedfont.com">
        <ListItemText primary="Hacked Font" />
      </ListItemLink>
    </List>
  </Layout>)
}

export default AboutPage